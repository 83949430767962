<template>
  <div class="document-invoice-card-root">
    <div class="document-row" @click.self="$emit('show-doc')">
      <div class="document-row-main w-full truncate" @click="$emit('show-doc')">
        <div>
          <div class="title w-full truncate">
            {{ name || $t('nameMissing') }}
          </div>
          <div class="-ml-1 flex items-center">
            <StatusBadge
              v-tooltip="tooltipScheduledDelivery"
              :status="status"
              :split="hasDeniedReasons ? 'deniedReasons' : ''"
            />
            <div class="document-type-badge">{{ $t(type).capitalize() }}</div>
          </div>
        </div>
      </div>
      <div v-if="!isCompact" style="display: inline-flex">
        <CDropdown position="is-bottom-left" style="margin-left: 1em">
          <template #trigger>
            <CButton
              v-tooltip="{
                content: $t('account.document.invoice.quickOptions'),
                distance: 5
              }"
              circle
              type="none"
              pattern="secondary"
              icon="more-vertical"
            />
          </template>
          <CDropdownItem
            v-for="(action, i) in documentActions"
            :key="i"
            :to="action.routerTo"
            :disabled="action.disabled"
            :type="action.type"
            :tooltip="action.tooltip"
            @click="action.routerTo ? null : action.onClick()"
          >
            {{ action.title }}
          </CDropdownItem>
        </CDropdown>
      </div>
    </div>
  </div>
</template>

<script>
import CDropdown from '@cling/components/ui/Dropdown'
import CDropdownItem from '@cling/components/ui/DropdownItem'
import StatusBadge from '@cling/components/ui/StatusBadge'

export default {
  i18nOptions: {
    namespaces: 'components'
  },
  name: 'DocumentInvoiceGroupItem',
  components: {
    StatusBadge,
    CDropdown,
    CDropdownItem
  },
  emits: ['show-doc'],
  props: {
    id: {
      type: [Number, String], // ?
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      required: true
    },
    status: {
      type: String,
      required: true
    },
    acceptedAt: {
      type: String,
      default: null
    },
    hasDeniedReasons: {
      type: Boolean,
      default: false
    },
    scheduledFirstDeliveryDate: {
      type: String,
      default: null
    },
    documentActions: {
      type: Array,
      default: () => []
    },
    isCompact: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    tooltipScheduledDelivery() {
      if (this.scheduledFirstDeliveryDate) {
        return {
          content: `${this.$t(
            'account.document.invoice.scheduledSend'
          )} ${this.$formatDate(this.scheduledFirstDeliveryDate, 'p - P')}`,
          classes: 'simple small',
          placement: 'bottom',
          offset: 8
        }
      }
      return {}
    }
  }
}
</script>

<style scoped lang="scss">
@import '@cling/styles/main.scss';

.document-row {
  padding: 0 1.5rem 0 0;
  display: flex;
  align-items: center;
  position: relative;
  flex-wrap: wrap;
  cursor: pointer;
  border-radius: 0.5em;
  /* box-shadow: 0 0 2px rgba(0,0,0,0.1), 0 3px 8px rgba(0,0,0,0.08); */
  /* box-shadow: 0 0 1px rgba(0, 0, 0, 0.3), 0 3px 5px -1px rgba(0, 0, 0, 0.15); */
  box-shadow: 0 0 0 2px $secondary;
  &:hover {
    /* box-shadow: 0 0 0 2px $secondary; */
    /* box-shadow: 0 0 1px rgba(0, 0, 0, 0.3), 0 3px 5px -1px rgba(0, 0, 0, 0.15), 0 0 0 2px $secondary; */
    box-shadow:
      0 0 0 6px rgba($secondary, 0.15),
      0 0 0 2px darken($secondary, 10%);
  }

  .title {
    font-size: calc(1rem + 2px);
    font-weight: 500;
    margin-bottom: 0.4rem !important;
  }
  &-main {
    padding: 1rem 1rem 1rem 1.5rem;
    flex: 1;
    margin-right: 0.5rem;
    display: flex;
    flex-wrap: nowrap;
  }
}

.document-type-badge {
  &::before {
    content: '\2002\00b7\2002';
  }
  /* box-shadow: inset 0 0 0 1px hsl(0, 0%, 80%); */
  color: hsl(0, 0%, 30%);
  font-weight: 500;
  font-size: 14px;
  margin-right: 0.5em;
  display: inline;
  border-radius: 4px;
}
</style>
