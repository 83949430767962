export default {
  account: {
    clients: {
      list: {
        importTitle: 'Nyhet! Nu kan be om omdömen från dina kunder.',
        importDescription:
          'Öka dina vinstchanser! Du kommer inom kort kunna presentera dina recensioner på dina offerter och profilsida.',
        importChatMessage:
          'Hej! Jag skulle vilja lägga till mina recensioner från följande länk: [Klistra in din länk här]',
        importLink: 'Importera recensioner från Google, Reco m.m.',
        createFirstClient: 'Skapa din första kund',
        noResult: 'Inga resultat hittades.',
        noResultDescription:
          'Skapa en kund så blir det lätta att söka upp dem när du ska skapa projekt.',
        createAClient: 'Skapa kund',
        resetSearch: 'Återställ sökning'
      },
      notePlaceholder: 'Syns enbart för dig och dina kollegor',
      noProjectTitle: 'Inga projekt finns för den här kunden',
      noProjectSubTitle:
        'Du har inte skapat några projekt för den här kunden ännu.'
    },
    document: {}, // ? unused atm
    invoices: {}, // TODO
    members: {}, // TODO
    onboarding: {
      submitButton: 'Gå till mitt konto',
      stepOne: {
        title: 'Välkommen! 👋',
        subTitle:
          'Redo att skicka snygga offerter & avtal? We sure are! Vi börjar med att ställa in utseendet.',
        questionTitle: 'Vilket tema passar dig bäst?',
        continueText: 'Du kan ändra det här senare.'
      },
      stepTwo: {
        title: '1 bild > 1 000 ord',
        subTitle:
          'Som uttrycket *nästan* går, vad berättar din story bättre än en bakgrundsbild?',
        questionTitle: 'Föreslagna bilder',
        continueText:
          'Mer utseendeinställningar hittar du under Inställningar -> Profil.'
      },
      panelDevice: {
        documentTitle: 'Rådgivning om konsultuppdrag',
        priceBlockOptions: '+2 st valbara tillägg',
        personalMessage:
          'Hej Per!<br> Tack för mötet senast. Här kommer en offert enligt det vi prata om. Utöver det vi prata om så finns 2 st valbara tillägg som jag rekommenderar.',
        parties: 'Parter',
        scopeOfWork: 'Omfattning'
      }
    },
    convertStripe: {
      title: 'Byt till kortbetalning',
      description:
        'Om du vill fortsätta använda {{brand,capitalize}} behöver du byta till kortbetalning. Vi stödjer inte månadsbetalningar med faktura längre.',
      button: 'Byt till kortbetalning'
    },
    unpaidStripe: {
      description:
        'Det finns obetalda fakturor för er prenumeration att hantera',
      button: 'Visa'
    },
    resurrectTrial: {
      title: `Välkommen tillbaka till $t(_common:brand.name)`,
      expired: 'Din obegränsade testperiod gick ut för {{- expiredAt}}.',
      startAgain: 'Starta en ny testperiod i 14 dagar till.',
      buttonText: 'Återställ testperiod',
      hintText:
        'Efter testperioden så återgår din kontoplan till gratisversionen.',
      successMessage: 'Obegränsad tillgång aktiverat! Gäller i 14 dagar'
    },
    overview: {
      current: 'Aktuella',
      archive: 'Arkiv',
      statistics: 'Statistik'
    },
    project: {
      error: 'Kunde inte hitta projekt',
      list: {
        sortOptions: {
          updatedAt: 'Senast ändrad',
          createdAt: 'Skapelsedatum'
        },
        noArchived: 'Du har inte arkiverat några projekt.',
        noProjects: 'Du har inte skapat några projekt.',
        radioProject: 'Visa projektnamn först',
        radioAddress: 'Visa adress först',
        filterCreatorButton: 'Skapad av'
      },
      calloutScheduledDelivery: {
        title: 'Schemalagt utskick',
        description: 'Dokumentet "{{docName}}" kommer att skickas',
        cancel: 'Avbryt utskick',
        deliveryDate: 'schemalagt för att skickas',
        dialog: {
          title: 'Ta bort schemalagt utskick',
          message: 'Är du säker på att du vill avbryta utskicket{{for}}?',
          forName: ' för {{name}}',
          cancel: 'Nej',
          submit: 'Ja, ta bort'
        }
      },
      clients: {
        condominiumId: 'Lgh-nr',
        condominiumOrgNo: 'Brf org-nr',
        propertyId: 'Beteckning',
        buttonClient: 'Gå till kund',
        buttonPropertyDesignation: 'Fastighetsbeteckning'
      },
      createDocumentDropdown: {
        mobileLabel: 'Vad för dokument?',
        active: 'Vad för dokument?',
        inActive: 'Nytt dokument',
        ataTooltip: '🔗 Skapa ett ändringsavtal som länkas till dokumentet.',
        regularTooltip: '📑 Skapa ett dokument kopplat till projektet.',
        regular: 'Vanligt dokument'
      },
      secondaryDropdown: {
        mobileLabel: 'Vad vill du fakturera?',
        active: 'Fakturera vad?',
        inActive: 'Fakturera',
        connectedTooltip: 'Fakturera ett dokument ⚡️',
        printActive: 'Välj dokument'
      },
      file: {
        addButton: 'Lägg till bilaga'
      },
      member: {
        leader: 'Ledare',
        added: 'Tillagd',
        promoteButton: 'Gör till ledare',
        promoteTooltipLeader: 'Är redan ledare',
        promoteTooltip: 'Har inte behörighet',
        removeButton: 'Ta bort från projekt',
        removeTooltip: 'Markera någon annan som ledare först',
        validation: {
          required: 'Ange en giltig e-post',
          email: 'Ange en giltig e-post',
          unique: 'Användaren är redan inbjuden'
        }
      },
      taskList: {
        placeholder: 'Skriv en task',
        draggingHint: 'Släpp utanför rutan för att ta bort'
      },
      taskModal: {
        placeholder: 'Skriv något du vill göra'
      },
      propertyDesignationModal: {
        alt1Title: 'Begär från kund',
        alt1Text:
          'Skicka ett meddelande till din kund så kan de fylla in det själva.',
        alt2Title: 'Fyll i själv',
        alt2Text:
          'Skriv in informationen om din kunds fastighetsbeteckning själv.',
        sentTitle: 'Meddelande skickat!',
        sentText:
          'Din kund kommer få en e-post där de kan fylla i sin information.'
      },
      propertyDesignationModalEdit: {
        title: 'Ange fastighetsbeteckning',
        realEstateRadio: 'Fastighet / Villa',
        condominiumRadio: 'Bostadsrätt',
        condominiumId: {
          label: 'Lägenhetsnummer',
          placeholder: 'Lgh-nr, 4 siffror'
        },
        condominiumOrgNo: {
          label: 'Bostadsrättsförenings org-nr',
          placeholder: 'Org-nr, 10 siffror'
        },
        validations: {
          realEstateId: {
            required: 'Ange fastighetsbeteckning'
          },
          condominiumId: {
            required: 'Ange lägenhetsnummer',
            numeric: 'Enbart siffror är tillåtna',
            minLength: 'Ange fyra siffror',
            maxLength: 'Ange fyra siffror'
          },
          condominiumOrgNo: {
            required: 'Ange Bostadsrättsförenings org-nr'
          }
        }
      }
    },
    settings: {
      avatarUploadButton: 'Ladda upp profilbild',
      baseLeaveDialog: {
        title: 'Vill du verkligen avbryta?',
        message: 'Du har osparade ändringar.',
        cancel: 'Stanna kvar',
        submit: 'Lämna sidan'
      },
      templateList: {
        calloutTitle: 'Skapa mall',
        calloutLink: 'Skapa mall'
      },
      photosVideos: {
        dropText: 'Släpp filer för att ladda upp',
        emptyText: 'Du har inte laddat upp några bilder.',
        namePlaceholder: 'Albumets namn',
        descriptionPlaceholder:
          'Beskriv vad bilderna visar. T.ex. vilket arbete du har gjort, vilka material du använde m.m.',
        validations: {
          name: {
            required: 'Ge albumet ett namn'
          }
        }
      },
      certificates: {
        searchPlaceholder: 'Sök efter certifikat eller välj från listan',
        noResults: 'Inga certifikat hittades',
        callout: {
          title: 'Professionella certifikat & behörigheter',
          description:
            'Kunder föredrar att välja professionella utförare som är licenserade inom sitt yrke.'
        },
        titles: {
          certificate: 'Certifieringar',
          tradeOrganization: 'Branschorganisationer',
          award: 'Utmärkelser',
          authorization: 'Auktorisationer',
          competence: 'Behörigheter',
          other: 'Övrigt'
        }
      },
      company: {
        type: {
          label: 'Bolagsform',
          placeholder: 'Välj form'
        },
        region: {
          label: 'Arbetsregion',
          tooltip: 'Avgör vilka regionspecifika funktioner som ska vara aktiva'
        },
        fTaxLabel: 'Innehar F-skatt',
        insuranceLabel: 'Har ansvarsförsäkring',
        successMessage: 'Företagsinställningar sparades',
        validations: {
          name: {
            required: 'Skriv in ditt företagsnamn',
            maxLengthValue: 'Max {{maxLength}} tecken'
          },
          email: {
            required: 'Ange ditt företags e-postadress',
            email: 'Din e-post ska vara i formatet: namn@exempel.se'
          },
          organisationNumber: {
            isValidOrgNoWrapper: 'Ange ett giltigt organisationsnummer'
          },
          street: {
            required: 'Ange en adress för ditt företag'
          },
          zip: {
            required: 'Ange ett postnummer för ditt företag'
          },
          city: {
            required: 'Ange en stad för ditt företag'
          }
        }
      },
      presentation: {
        label: 'Presentation',
        placeholder:
          'Förklara vad som gör att just ditt företag sticker ut i mängden och varför du gör ett utmärkt arbete.',
        successMessage: 'Företagsintroduktion uppdaterades',
        callout: {
          title: 'Presentera dig sjäv',
          description:
            'Förklara vad som gör att just ditt företag sticker ut i mängden och varför du gör ett utmärkt arbete. Du kan t.ex. nämna hur många år du har arbetat, vad du är passionerad för eller om du har några speciella färdigheter eller utrustning.'
        },
        validations: {
          about: {
            required: 'Skriv en liten text om ditt företag'
          }
        }
      },
      language: {
        localeLabel: 'Datum / Siffror',
        successMessage: 'Språkinställningar uppdaterades'
      },
      notifications: {
        viewed: 'Få notifikation och e-post när kund öppnat offert / avtal',
        acceptedSendEmail: 'Få e-post när kund godkänt ett dokument',
        acceptedSendSms: 'Få SMS när kund godkänt ett dokument',
        getCopyAll: 'Få kopia på alla användarens notifikationer',
        semiSigned:
          'Få notifikation och e-post när ett dokument blir delsignerat',
        invitedProjectCreate:
          'Få notifikation när någon bjuder in mig till ett projekt',
        invitedProjectSendEmail:
          'Få e-post när någon bjuder in mig till ett projekt',
        successMessage: 'E-post utskick uppdaterades',
        clientEmailReplyToUser: {
          label: 'Använd min e-post som svarsadress för e-postutskick',
          subLabel: 'Använder {{email}}'
        },
        newLoginAlertEmail: 'Få e-post när en ny enhet loggar in på mitt konto'
      },
      passwordModal: {
        current: 'Nuvarande lösenord',
        new: 'Nytt lösenord',
        successMessage: 'Uppdaterade lösenord',
        title: 'Byt lösenord',
        validations: {
          passwordCurrent: {
            required: 'Skriv in ditt nuvarande lösenord'
          },
          passwordNew: {
            required: 'Skriv in ditt nya lösenord',
            minLength: 'Ange minst 8 tecken'
          }
        },
        dialog: {
          title: 'Osparade ändringar',
          message: 'Är du säker på att du vill avbryta?',
          cancel: 'Stanna kvar',
          submit: 'Ja, stäng'
        }
      },
      project: {
        priceInputDefault: 'Förval för att mata in priser till konsumenter',
        priceInputCompany: 'Förval för att mata in priser till företagskunder',
        defaultVatRate: 'Skapa nya artiklar med momssats',
        defaultLanguage: 'Skapa nya dokument på språk',
        defaultCurrency: 'Skapa nya dokument med valuta',
        defaultClientType: 'Skapa nya kunder som',
        nextProjectNumber: 'Nästa projekt-nr',
        nextOfferNumber: 'Nästa offert-nr',
        nextContractNumber: 'Nästa avtal-nr',
        nextAtaNumber: 'Nästa nr för tilläggsavtal',
        successMessage: 'Projektinställningar uppdaterades',
        houseWorkDeduction: {
          title: 'ROT/RUT-avdrag',
          description:
            'Om du arbetar med avdragbara tjänster kan du aktivera automatisk uträkning.'
        },
        hidePrices: {
          title: 'Dölja priser för varor / tjänster',
          description:
            'Aktivera ifall du vill gömma priset för utvalda varor / tjänster när du skapar dokument.',
          allowHidePrice: 'Aktivera döljbara priser',
          hidePriceOnNewlyAddedArticles:
            'Dölj priser för nytillagda varor / tjänster',
          hideDocArticlePriceInput:
            'Dölj inmatning av pris som standard för varor / tjänster'
        },
        documentSenderSign: {
          title: 'Signera dina egna dokument',
          description:
            'Bestäm förvalet ifall du vill vill stå med som undertecknare på dokument du skapar.',
          label: 'Signera som avsändare'
        },
        autoExpireDoc: {
          defaultExpireDays: 'Standard giltighetstid dagar'
        },
        reminders: {
          off: 'Avstängd',
          viewed: 'Kund har inte visat dokumentet efter',
          reminder: 'Kund har inte svarat efter',
          expire: 'När giltighetstiden är inom'
        },
        validations: {
          nextProjectNumber: {
            required: 'Skriv in ett nummer för nästa projekt',
            numeric: 'Enbart siffror är tillåtna'
          },
          nextOfferNumber: {
            required: 'Skriv in ett nummer för nästa offert',
            numeric: 'Enbart siffror är tillåtna'
          },
          nextContractNumber: {
            required: 'Skriv in ett nummer för nästa avtal',
            numeric: 'Enbart siffror är tillåtna'
          },
          nextAtaNumber: {
            required: 'Skriv in ett nummer för nästa tillägsavtal',
            numeric: 'Enbart siffror är tillåtna'
          }
        }
      },
      tab: {
        default: {
          selectSetting: 'Välj en inställning i listan till vänster.',
          inTheSpotlight: 'I blickfånget',
          configureTheme: 'Ställ in tema',
          theme: {
            title: 'Designa ett dokument',
            description:
              'Använd temaskaparen för att skapa ett snyggt dokument.',
            actionText: 'Skapa tema ->'
          }
        },
        photos: {
          title: 'Mina foton',
          calloutTitle: 'Visa upp ditt företag',
          calloutDescription:
            'Inkludera foton av ditt arbete (före och efter), team, arbetsplats, eller utrustning.'
        },
        templates: {
          terms: {
            title: 'Mina villkor',
            subTitle: 'Anpassa dina allmänna villkor',
            description:
              'Om du har egna villkor som du vill ska gälla på offerter & avtal så kan du ställa in dem här.',
            link: 'Ställ in'
          }
        },
        term: {
          form: {
            nameLabel: 'Namn på villkor',
            radio: {
              button: 'Formulär',
              tooltip: 'Ta bort PDF för att byta'
            },
            text: {
              label: 'Mallens innehåll',
              placeholder: 'Skriv text här...'
            },
            dialog: {
              title: 'Vill du verkligen avbryta?',
              message: 'Du har inte sparat dina ändringar för villkoret.',
              cancel: 'Stanna kvar',
              submit: 'Lämna sidan'
            },
            validations: {
              name: {
                required: 'Ge mallen ett namn',
                maxLength: 'Får max vara 256 tecken långt'
              },
              textContent: {
                required: 'Skriv vad mallen ska innehålla'
              }
            }
          }
        }
      },
      user: {
        logoutAllDevices: {
          button: 'Logga ut alla enheter',
          confirmTitle: 'Logga ut alla enheter?',
          confirmText: 'Du behöver logga in igen för att komma åt ditt konto.'
        },
        changePasswordMainAccount:
          'För att redigera ditt lösenord växla först till ditt huvudkonto',
        callout: {
          title: 'Exempel och tips',
          tip1: 'Se till att le.',
          tip2: 'Ta fotot i dagsljus. Utan blixt.',
          tip3: 'Håll kameran i ögonnivå.',
          tip4: 'Ha en enkel bakgrund.'
        },
        validations: {
          firstName: {
            required: 'Skriv in ditt förnamn så att kunder vet vem du är'
          },
          lastName: {
            required: 'Skriv in ditt efternamn så att kunder vet vem du är'
          },
          email: {
            required: 'Skriv in din e-postadress',
            email: 'Din e-post ska vara i formatet: namn@exempel.se'
          }
        }
      },
      social: {
        placeholderAddress: 'Skriv din {{name}}-adress',
        validWebsite:
          'Fel format. Länken kan t.ex. vara http://www.minhemsida.se',
        validSocialUrl:
          'Fel format. Länken kan t.ex. vara http://{{address}}/mittkonto',
        successMessage: 'Dina länkar uppdaterades'
      }
    },
    timeline: {
      modal: {
        invite: 'Bjud in ny medlem',
        name: 'Planera något',
        comment: 'Lämna en kommentar eller en uppdatering'
      }
    },
    notificationsPage: {
      markAll: 'Markera alla som lästa',
      emptyText: 'Du är up-to-date!'
    }
  },
  publicDocument: {
    pdf: {
      signing: 'Signering',
      signature: 'Signatur',
      locationAndDate: 'Ort och datum',
      printedName: 'Namnförtydligande',
      error: {
        title: 'Kunde inte skapa PDF.',
        description: 'Var vänlig och försök igen.'
      }
    },
    pdfVerification: {
      verifyAt:
        'Det här dokumentet har verifierats av {{ brandName }}. Dokumentet är förslutet med dess elektroniska signaturer. Äktheten av dokumentet går att bevisa matematiskt av en oberoende part och av {{ brandName }}. För er bekvämlighet så kan du även säkerställa dokumentets äkthet på: ',
      waitingForAnswer: 'Väntar på svar',
      recipient: {
        signedBankId: 'Undertecknad med BankID av ',
        verifiedLogin: 'Verifierat med autentiserad inloggning'
      },
      signedAttachments: {
        title: 'Signerade bilagor'
      },
      inPerson: {
        signature: 'Signatur',
        locationAndDate: 'Ort och datum',
        printedName: 'Namnförtydligande'
      }
    },
    events: {
      sent: 'Dokument skickades till {{to}}.',
      viewedName: '{{name}} har visat dokumentet',
      viewed: 'Har visats',
      accepted: 'Dokumentet undertecknades av {{by}}.',
      denied: 'Dokumentet nekades av {{by}}.',
      statusChanged: 'Dokumentet markerades som {{status}}.',
      requestChange: 'Dokumentet markerades manuellt som {{status}}.',
      expired: 'Dokumentets giltighetstid har passerat.'
    }
  },
  notFound: {
    title: 'Sidan kunde inte hittas',
    subTitle:
      'Verkar som att länken är trasig eller så har sidan tagits bort. Pröva någon av dessa länkar istället:',
    homepage: 'Startsida',
    createAccount: 'Skapa konto',
    chat: 'Chatta med oss',
    chatMessage: 'Hej! Jag lyckas inte komma åt en sida. Kan ni hjälpa mig?'
  },
  login: {
    title: 'Välkommen tillbaka',
    forgottenLink: 'Glömt lösenord?',
    userIdPlaceholder: 'AnvändarId',
    missingText: 'Har du inget konto?',
    createLink: 'Skapa ett konto',
    validations: {
      email: {
        required: 'Ange E-post',
        email: 'E-post är ogiltig, måste vara: namn@exempel.se'
      },
      password: {
        required: 'Ange lösenord'
      }
    }
  },
  forgottenPassword: {
    title: 'Glömt ditt lösenord?',
    description:
      'Skriv in din e-postadress så skickar vi en återställningslänk till din inkorg.',
    resetButton: 'Återställ lösenord',
    hintText: 'Kom du ihåg ditt lösenord?',
    didRequest: {
      title: 'E-post skickat!',
      description:
        'Om det fanns en användare för {{email}} så ska du ha fått en återställningslänk skickad till din e-postinkorg.'
    }
  },
  newPassword: {
    title: 'Välj ett nytt lösenord',
    placeholder: 'Skriv in ett nytt lösenord'
  },
  register: {
    title: 'Skapa ett konto och vinn fler affärer',
    hintText: 'Har du redan ett konto?',
    demo: {
      title: 'Registrera demokonto',
      subTitle: 'Skapa nytt demokonto för ett lead',
      button: 'Skapa konto',
      companyPlaceholder: 'Ditt företagsnamn',
      emailPlaceholder: 'Skriv in din e-post',
      cellphonePlaceholder: '(Valfritt) Skriv in ett telefonnummer',
      smsLabel: 'Skicka länk per sms?',
      manualLabel: 'Manuell onboarding?',
      employee: {
        label: 'Vem som öppnar demokontot',
        placeholder: 'Välj vem som öppnar demokontot'
      },
      greeting: {
        title: 'Skapa vinnande offerter på nolltid',
        subTitle:
          'Med {{brand,capitalize}} blir det lätt att hålla många bollar uppe i luften.'
      }
    },
    existing: {
      title: 'Gå med i {{companyName}}',
      subTitle:
        'Du har blivit inbjuden att gå med i deras {{brand,capitalize}}-team.',
      subTitleByName:
        '{{invitedByName}} har skickat en inbjudan till dig om att gå med i deras {{brand,capitalize}}-team.',
      passwordPlaceholder: 'Välj ett lösenord',
      terms: {
        text: 'Jag godkänner de',
        link: 'allmänna villkoren'
      }
    },
    deprecatedBrand: {
      text: 'Det är inte längre möjligt att skapa konto här. Gå till {{url}} för att skapa ett nytt konto',
      link: 'Gå till {{url}}'
    }
  },
  unsubscribe: {
    title: 'Avregistrera',
    description:
      'Avregistrerar du dig kommer du <strong>inte</strong> kunna ta emot dokument från företag som använder {{brand,capitalize}}, eller vår egna kommunikation.',
    placeholder: 'Skriv in din e-post',
    button: 'Avregistrera för e-post',
    spinnerText: 'Avregistrerar',
    supportLinkTitle: 'Råkade du avregistrera dig av misstag?',
    success: {
      title: 'Du är nu avregistrerad',
      description:
        'Du kommer inte längre få e-post från oss. Råkade du avregistrera dig av misstag?',
      link: 'Kontakta support.'
    },
    error: {
      title: 'Något gick fel',
      description1:
        'Oj! Nu gick något väldigt fel. Vi kunde tyvärr inte avregistrera dig för utskick, vänligen',
      description2: 'så ser vi till att lösa det.',
      contactUs: 'kontakta oss',
      theSupport: 'supporten',
      link1: 'Kontakta oss på',
      link2: 'för att få hjälp.'
    },
    tokenError: {
      title: 'Något gick fel',
      description:
        'Vi kunde inte automatiskt verifiera din avregistrering, vill du manuellt avregistrera din e-post istället?',
      button: 'Avregistrera med e-post'
    },
    validations: {
      email: {
        required: 'Skriv in din e-postadress',
        email: 'Din e-post ska vara i formatet: namn@exempel.se'
      }
    }
  }
}
