<template>
  <div ref="fileRow" v-click-outside="abortEdit" style="position: relative">
    <div
      v-if="uploadProgress !== null"
      :style="{ width: `${uploadProgress}%` }"
      class="progress-wrapper"
    >
      <div class="progress-bar-stripe" />
    </div>
    <div
      v-if="isEditing"
      :class="[{ 'is-focus': isEditing }]"
      class="c-flex-row c-align-middle item-row"
    >
      <FileIcon :mime="mime" />
      <CField grouped style="flex: 1; width: 100%">
        <CInput
          ref="nameInput"
          v-model="displayName"
          filled
          size="small"
          expanded
          @keyup.enter="emitSubmit"
        />
        <CButton
          v-tooltip="{
            content: $t('save'),
            placement: 'bottom',
            distance: 0
          }"
          type="primary"
          circle
          @click="emitSubmit"
        >
          <CIcon type="check" size="16" />
        </CButton>
      </CField>
    </div>
    <div v-else>
      <div class="c-flex-row c-align-middle item-row">
        <component
          :is="isImage ? 'div' : 'a'"
          :href="href"
          target="_blank"
          class="c-flex-row c-align-middle"
          style="cursor: pointer; min-width: 0"
          @click="$emit('click')"
        >
          <img
            v-if="isImage && mime.startsWith('image')"
            :src="href"
            class="item-preview"
          />
          <FileIcon v-else :mime="mime" :size="40" style="width: 60px" />
          <div style="padding-left: 1rem; flex: 1; min-width: 0">
            <div
              :style="{ opacity: !name ? '0.5' : 'initial' }"
              class="item-text w-full truncate"
            >
              {{ name || $t('nameMissing') }}
            </div>
            <div class="item-date">
              {{
                createdAt
                  ? $formatDate(createdAt, 'dd MMM, yyyy')
                  : `${uploadProgress}% ${$t('uploading')}`
              }}
            </div>
          </div>
        </component>
        <CDropdown position="is-bottom-left">
          <template #trigger>
            <CButton
              circle
              size="normal"
              type="none"
              pattern="secondary"
              icon="more-vertical"
            />
          </template>
          <CDropdownItem @click="isEditing = true">{{
            $t('change', { thing: $t('name') }).capitalize()
          }}</CDropdownItem>
          <CDropdownItem
            type="danger"
            @click="$emit('remove', projectFileId)"
            >{{ $t('remove') }}</CDropdownItem
          >
        </CDropdown>
      </div>
    </div>
  </div>
</template>
<script>
import FileIcon from '@cling/components/fileIcon/Index.vue'
import CDropdown from '@cling/components/ui/Dropdown'
import CDropdownItem from '@cling/components/ui/DropdownItem'
import CField from '@cling/components/ui/FormField'

export default {
  name: 'ProjectFileItem',
  components: {
    FileIcon,
    CDropdown,
    CDropdownItem,
    CField
  },
  emits: ['click', 'remove', 'submit'],
  props: {
    name: {
      type: String,
      default: null
    },
    id: {
      type: [Number, String], // String used for currently uploading files
      required: true
    },
    mime: {
      type: String,
      default: ''
    },
    href: {
      type: String,
      required: true
    },
    uploadProgress: {
      type: [String, Number],
      default: null
    },
    projectId: {
      type: [Number, String],
      required: true
    },
    projectFileId: {
      type: [Number, String],
      required: true
    },
    isImage: {
      type: Boolean,
      default: false
    },
    createdAt: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      isEditing: false,
      displayName: ''
    }
  },
  watch: {
    isEditing(val) {
      if (val) {
        this.$nextTick(() => this.$refs.nameInput.focus())
      }
    }
  },
  mounted() {
    this.displayName = this.name // Set name in form
  },
  methods: {
    abortEdit() {
      setTimeout(() => {
        this.isEditing = false
      }, 0)
    },
    emitSubmit() {
      // Get first 255 characters only
      this.$emit('submit', {
        id: this.id,
        name: this.displayName.substring(0, 255)
      })
      this.abortEdit()
    }
  }
}
</script>

<style scoped lang="scss">
@import '@cling/styles/main.scss';

.item-row {
  background-color: hsl(0, 0%, 96%);
  margin-bottom: 6px;
  align-items: center;
  padding: 0.5em 1em 0.5em 0.5em;
  border-radius: 0.5em;
  cursor: pointer;
  font-size: 16px;
  font-weight: 400;
  color: $black;
  position: relative;
  & .item-text {
    color: $black;
  }
  & .item-date {
    color: hsl(0, 0%, 40%);
    font-size: 14px;
  }
  &:hover,
  &.is-focus {
    box-shadow: inset 0 0 0 2px $gray300;
  }
  & .item-preview {
    border-radius: 3px;
    min-height: 1px;
    height: auto;
    width: 60px;
  }
}

.progress-wrapper {
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  /* background-color: $white; */
  overflow: hidden;
  border-radius: 0.5rem;
  transition: width 0.2s;
  height: 100%;
  width: 0%;
  pointer-events: none;
}
$stripeBg: $gray400;
$stripe: linear-gradient(
  45deg,
  $stripeBg 0%,
  $stripeBg 25%,
  transparent 25%,
  transparent 50%,
  $stripeBg 50%,
  $stripeBg 75%,
  transparent 75%,
  transparent 100%
);

.progress-bar-stripe {
  height: 100%;
  width: calc(100% + 200px);
  background-size: 100px 100px;
  opacity: 0.3;
  background-image: $stripe;
  animation: counter-progress 3s linear infinite;
}
@keyframes counter-progress {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-200px);
  }
}
</style>
