<template>
  <hr v-if="separator" class="dropdown-divider" />
  <component
    :is="to && !disabled ? 'router-link' : 'a'"
    v-else-if="!custom && !hasLink"
    v-tooltip="tooltipItem"
    :to="to"
    :class="[anchorClasses, `is-${type}`]"
    :role="ariaRoleItem"
    :tabindex="isFocusable ? 0 : null"
    class="dropdown-item w-full truncate"
    @click="selectItem"
  >
    <CIcon
      v-if="iconLeft"
      v-bind="{ size: 18, ...iconProps }"
      :type="iconLeft"
      class="icon dropdown-item-icon"
    />
    <slot />
    <CSwitch
      v-if="toggle"
      v-tooltip="tooltipItem"
      :model-value="value"
      size="tiny"
      type="success"
      style="
        display: inline-flex;
        pointer-events: none;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
        margin-right: 0;
      "
    />
    <CIcon
      v-if="iconRight"
      v-bind="{ size: 18, ...iconProps }"
      :type="iconRight || 'chevron-up-down'"
      class="icon dropdown-item-icon is-right"
    />
  </component>
  <div
    v-else
    :class="[itemClasses, `is-${type}`]"
    :role="ariaRoleItem"
    :tabindex="isFocusable ? 0 : null"
    class="w-full truncate"
    @click="selectItem"
  >
    <CIcon
      v-if="iconLeft"
      v-bind="{ size: 18, ...iconProps }"
      :type="iconLeft"
      class="icon dropdown-item-icon"
    />
    <slot />
    <CIcon
      v-if="iconRight"
      v-bind="{ size: 18, ...iconProps }"
      :type="iconRight"
      class="icon dropdown-item-icon is-right"
    />
  </div>
</template>

<script>
import { DROPDOWN_INJECTION_KEY } from '@cling/components/ui/Dropdown/CDropdown.vue'
import CSwitch from '@cling/components/ui/Switch'

export default {
  name: 'CDropdownItem',
  components: {
    CSwitch
  },
  inject: {
    parent: {
      from: DROPDOWN_INJECTION_KEY,
      default: undefined
    }
  },
  props: {
    value: {
      type: [String, Number, Boolean, Object, Array, Function],
      default: null
    },
    separator: Boolean,
    disabled: Boolean,
    custom: Boolean,
    focusable: {
      type: Boolean,
      default: true
    },
    paddingless: Boolean,
    hasLink: Boolean,
    ariaRole: {
      type: String,
      default: ''
    },
    type: {
      type: String,
      default: null
    },
    toggle: {
      type: Boolean,
      default: false
    },
    title: {
      type: String,
      default: ''
    },
    tooltip: {
      type: [Object, String],
      default: null
    },
    closeOnClick: {
      type: Boolean,
      default: true
    },
    iconLeft: {
      type: String,
      default: null
    },
    iconRight: {
      type: String,
      default: null
    },
    iconProps: {
      type: Object,
      default: () => ({})
    },
    to: {
      type: Object,
      default: null
    },
    // Allows for parent communication on arbitrary depth
    customParent: {
      type: Object,
      default: null
    }
  },
  emits: ['click', 'input'],
  computed: {
    anchorClasses() {
      return {
        'is-disabled': this.parent?.disabled || this.disabled,
        'is-paddingless': this.paddingless,
        'is-active': this.isActive,
        'has-toggle': this.toggle
      }
    },
    itemClasses() {
      return {
        'dropdown-item': !this.hasLink,
        'is-disabled': this.disabled,
        'is-paddingless': this.paddingless,
        'is-active': this.isActive,
        'has-link': this.hasLink
      }
    },
    ariaRoleItem() {
      return this.ariaRole === 'menuitem' || this.ariaRole === 'listitem'
        ? this.ariaRole
        : null
    },
    isClickable() {
      return (
        !this.parent?.disabled &&
        !this.separator &&
        !this.disabled &&
        !this.custom
      )
    },
    isActive() {
      if (this.toggle) return false
      if (this.parent?.selected === null) return false
      if (this.parent?.multiple)
        return this.parent?.selected.indexOf(this.value) >= 0
      return this.value === this.parent?.selected
    },
    isFocusable() {
      return this.hasLink ? false : this.focusable
    },
    tooltipItem() {
      if (!this.tooltip) return null

      const tooltipObj =
        typeof this.tooltip === 'string'
          ? { content: this.tooltip }
          : this.tooltip

      return {
        boundary: `.${this.parent?.uniquePopoverClass}`,
        popperClass: 'dropdown-tooltip-class',
        distance: 7,
        placement: 'left',
        ...tooltipObj
      }
    }
  },
  created() {
    if (!this.parent?.$data._isDropdown) {
      this.$destroy()
      throw new Error('You should wrap cDropdownItem on a cDropdown')
    }
  },
  methods: {
    /**
     * Click listener, select the item.
     */
    selectItem() {
      if (!this.isClickable) return
      this.parent?.selectItem(this.value, { closeOnClick: this.closeOnClick })
      this.$emit('click')
      if (typeof this.value === 'boolean') {
        this.$emit('input', !this.value)
      }
    }
  }
}
</script>
<style lang="scss">
.dropdown-tooltip-class.dropdown-tooltip-class .v-popper__inner {
  max-width: calc(13 * var(--rem));
}

.dropdown-item-icon {
  width: 1.25em;
  height: 1.25em;
  color: hsl(0, 0%, 42%);
  margin-top: 0.25em;
  &:not(.is-right) {
    margin-right: 0.875em;
  }
  &.is-right {
    margin-left: auto;
    position: absolute;
    right: 0.5em;
    top: 50%;
    transform: translateY(-50%);
  }
}

// Check ranking style specificity before changing selector
.dropdown-item.has-toggle:not(.text-right) {
  padding-right: 40px;
}

a.dropdown-item.dropdown-item.is-active,
button.dropdown-item.dropdown-item.is-active {
  @apply bg-primary-50 text-primary-600;
}
</style>
