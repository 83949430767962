<template>
  <div
    :class="{ 'full-screen': isFullScreen }"
    class="font-inter modal-overlay"
    @click.passive.self="$emit('close')"
  >
    <div :style="!isFullScreen && { padding: '2rem' }" class="modal-container">
      <CButton
        v-if="isFullScreen"
        class="modal-close"
        pattern="tertiary"
        type="none"
        circle
        icon="x"
        @click="$emit('close')"
      />
      <div class="modal-main">
        <div v-if="step === 0">
          <div style="font-size: 1.5em; font-weight: 600">Skapa faktura</div>
          <div style="color: hsl(0, 0%, 35%)">
            Skapa ett fakturautkast från Cling som skickas in till ditt
            Fortnox-konto.
          </div>
          <div style="padding: 2em 0 3em 0">
            <div
              style="
                display: grid;
                grid-template-columns: 1fr 1fr;
                grid-gap: 10px;
                align-items: center;
              "
            >
              <div>
                <div style="font-weight: 500">Kund i Fortnox-register</div>
                <div style="font-size: 13px; color: hsl(0, 0%, 45%)">
                  Skapa ny eller koppla till befintlig.
                </div>
              </div>
              <div
                style="
                  font-size: 14px;
                  background-color: hsl(0, 0%, 93%);
                  padding: 3px;
                  border-radius: 5px;
                  display: inline-flex;
                  align-items: center;
                  flex-wrap: nowrap;
                  margin-left: auto;
                  color: hsl(0, 0%, 50%);
                "
              >
                <div
                  :style="
                    !useCustomCustomer &&
                    'background-color: white; box-shadow: 0 2px 2px rgba(0,0,0,0.2); color: hsl(0, 0%, 8%);'
                  "
                  style="
                    padding: 5px 8px;
                    border-radius: 3px;
                    font-weight: 500;
                    cursor: pointer;
                  "
                  @click="useCustomCustomer = false"
                >
                  Ny kund
                </div>
                <div
                  :style="
                    useCustomCustomer &&
                    'background-color: white; box-shadow: 0 2px 2px rgba(0,0,0,0.2); color: hsl(0, 0%, 8%);'
                  "
                  style="
                    padding: 5px 8px;
                    border-radius: 3px;
                    font-weight: 500;
                    cursor: pointer;
                  "
                  @click="
                    () => {
                      useCustomCustomer = true
                      $nextTick(() => $refs.input.focus())
                    }
                  "
                >
                  Befintlig kund
                </div>
              </div>
            </div>
            <div v-if="useCustomCustomer">
              <div
                style="
                  padding: 1em;
                  background-color: hsl(var(--gray-color-100) / 1);
                  border-radius: 5px;
                  margin-top: 1em;
                "
              >
                <div style="font-size: 14px; padding-bottom: 1em">
                  Ange ett kundnummer för att koppla fakturan till en av dina
                  kunder i Fortnox.
                </div>
                <CField label="Kundnummer" label-position="inside">
                  <CAutocomplete
                    ref="input"
                    v-model="searchQuery"
                    :data="searchResults"
                    :loading="isLoadingCustomers"
                    placeholder="Ange kundnummer eller sök"
                    expanded
                    keep-first
                    style="width: auto"
                    @typing="search($event)"
                    @select="useSearchResult($event)"
                  >
                    <template #default="{ option }">
                      <div style="line-height: 1.5">
                        <div style="font-weight: 500">
                          {{ option.CustomerNumber }}
                        </div>
                        <div class="has-text-grey-dark" style="font-size: 14px">
                          {{ option.Name ? `${option.Name} \&middot; ` : '' }}
                          {{
                            option.Email ||
                            option.OrganisationNumber ||
                            '\&nbsp;'
                          }}
                        </div>
                      </div>
                    </template>
                    <template #footer>
                      <div>
                        <CButton
                          v-if="searchQuery && !searchResults.length"
                          type="secondary"
                          pattern="tertiary"
                          size="small"
                          style="font-weight: 600"
                          @click="useCustomCustomer = false"
                        >
                          Ny kund
                        </CButton>
                      </div>
                    </template>
                    <template #empty> Inga resultat </template>
                  </CAutocomplete>
                </CField>
                <div v-if="selectedCustomerObj">
                  <div style="font-weight: 500">
                    Kundnummer: {{ selectedCustomerObj.CustomerNumber }}
                  </div>
                  <div class="has-text-grey-dark" style="font-size: 14px">
                    {{
                      selectedCustomerObj.Name
                        ? `${selectedCustomerObj.Name} \&middot; `
                        : ''
                    }}
                    {{
                      selectedCustomerObj.Email ||
                      selectedCustomerObj.OrganisationNumber ||
                      '\&nbsp;'
                    }}
                  </div>
                </div>
              </div>
            </div>
            <div
              v-if="errorMessage"
              style="
                background-color: hsl(0, 100%, 95%);
                padding: 1em;
                font-size: 15px;
                font-weight: 500;
                border-radius: 0.5em;
                border: 1px solid hsl(0, 100%, 85%);
                display: flex;
                margin-top: 2em;
              "
            >
              <CIcon
                type="warning"
                size="20"
                style="
                  color: hsl(0, 80%, 60%);
                  flex-shrink: 0;
                  margin-right: 1em;
                "
              />
              <div>
                {{ errorMessage }}
              </div>
            </div>
          </div>
          <div>
            <CButton
              :disabled="
                useCustomCustomer &&
                (!selectedCustomerObj || !selectedCustomerObj.CustomerNumber)
              "
              :loading="isPostingInvoice"
              type="secondary"
              wide
              @click="createInvoice"
            >
              Skapa faktura
            </CButton>
          </div>
        </div>
        <div v-if="step === 1">
          <div style="font-size: 1.5em; font-weight: 600">Faktura skapad</div>
          <div style="color: hsl(0, 0%, 35%)">
            En faktura har skapats som utkast i Fortnox.
          </div>
          <div
            v-if="createdInvoice"
            style="
              margin-top: 2em;
              padding: 0 1em;
              border: 1px solid hsl(0, 0%, 88%);
              border-radius: 7px;
              font-size: 15px;
            "
          >
            <div
              style="
                display: grid;
                grid-template-columns: 1fr 1fr;
                padding: 1em 0.5em;
              "
            >
              <div style="font-weight: 500">Fakturanummer</div>
              <div style="color: hsl(0, 0%, 35%)">
                {{ createdInvoice.DocumentNumber }}
              </div>
            </div>
            <div
              style="
                display: grid;
                grid-template-columns: 1fr 1fr;
                padding: 1em 0.5em;
                border-top: 1px solid hsl(0, 0%, 88%);
              "
            >
              <div style="font-weight: 500">Kund</div>
              <div style="color: hsl(0, 0%, 35%)">
                {{ createdInvoice.CustomerNumber }}
              </div>
            </div>
          </div>
          <CCallout
            v-if="createdTaxReduction"
            key="1"
            title="Husarbete synkat"
            style="margin-top: 2em"
          >
            Grundläggande uppgifter om husarbete är synkade på fakturan.
          </CCallout>
          <CCallout
            v-if="createdInvoice && taxReductionErrorMessage"
            key="2"
            title="Kunde inte synka husarbete"
            type="warning"
            style="margin-top: 2em"
          >
            {{ taxReductionErrorMessage }}
          </CCallout>
          <CButton
            type="secondary"
            size="medium"
            wide
            style="margin-top: 2em"
            @click="$emit('close')"
          >
            Stäng
          </CButton>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { appendProjectNote, fortnoxApi } from '@cling/api'
import CAutocomplete from '@cling/components/ui/Autocomplete'
import CCallout from '@cling/components/ui/Callout'
import CField from '@cling/components/ui/FormField'
import windowSize from '@cling/mixins/windowSize'

import debounce from 'lodash/debounce'
import get from 'lodash/get'

export default {
  name: 'DocumentExportFortnoxModal',
  components: {
    CAutocomplete,
    CCallout,
    CField
  },
  emits: ['close'],
  mixins: [windowSize],
  props: {
    document: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      step: 0,
      searchResults: [], // Results when searching
      searchQuery: '', // query used to search customers
      emptyQuery: false, // Last query that did not have any results
      isLoadingCustomers: false,
      isPostingInvoice: false,
      createdInvoice: null,
      createdTaxReduction: null,
      errorMessage: null,
      taxReductionErrorMessage: null,
      selectedCustomerObj: null, // The Fortnox customer obj that is reused if not new
      useCustomCustomer: false
    }
  },
  computed: {
    isFullScreen() {
      return this.mq === 'sm'
    }
  },
  created() {
    this.initInvoice()
  },
  methods: {
    async initInvoice() {
      try {
        if (this.isLoadingCustomers) return
        this.errorMessage = null
        this.isLoadingCustomers = true
        this.searchResults = []
        // Client
        if (!this.document.clients || !this.document.clients[0])
          throw new Error('No main client on document to use')

        const mainClient = this.document.clients[0]
        const { email } = mainClient
        if (email) {
          // Is there any existing customer in Fortnox we can use?
          const { data } = await fortnoxApi.searchCustomers(email)
          if (data && Array.isArray(data)) this.searchResults = data
          if (data && data[0]) {
            // Suggest to re-use this
            ;[this.selectedCustomerObj] = data
            this.useCustomCustomer = true
          }
        }
      } catch (err) {
        if (err && err.message) this.errorMessage = err.message
        this.$error(err)
      } finally {
        this.isLoadingCustomers = false
      }
    },
    async createInvoice() {
      this.errorMessage = null
      if (this.isPostingInvoice) return
      try {
        this.isPostingInvoice = true

        const invoiceData = {
          articles: this.document.articles
        }

        // If reverseVat Fortnox asks us to set vat to 0 for each article
        if (get(this.document.prices, 'region.reverseVat', false)) {
          invoiceData.articles.forEach(article => {
            article.vat = 0
          })
        }

        if (this.useCustomCustomer) {
          if (
            !this.selectedCustomerObj ||
            !this.selectedCustomerObj.CustomerNumber
          )
            throw new Error('CustomerNumber not found')
          invoiceData.existingCustomerNumber =
            this.selectedCustomerObj.CustomerNumber
        } else [invoiceData.client] = this.document.clients // Use first client

        // Any deduction?
        const deductionType = get(
          this.document,
          'data.prices.region.houseWorkType'
        )
        if (['rot', 'rut'].includes(deductionType))
          invoiceData.TaxReductionType = deductionType
        else if (deductionType && deductionType.startsWith('green'))
          invoiceData.TaxReductionType = 'green'

        const { data: invoice } = await fortnoxApi.createInvoice(invoiceData)
        this.createdInvoice = invoice

        if (invoiceData.TaxReductionType) await this.createTaxReduction()
        this.step = 1
        this.createProjectNote()
      } catch (err) {
        if (err && err.message) this.errorMessage = err.message
        this.$error(err)
        this.step = 0
      } finally {
        this.isPostingInvoice = false
      }
    },
    async createTaxReduction() {
      try {
        if (!this.createdInvoice) return

        // Property designation is on the main client
        const { clients } = this.document
        const [mainClient] = clients
        if (!mainClient) return
        const { name, socialNo } = mainClient
        if (!name) {
          this.taxReductionErrorMessage =
            'Kundnamn saknas för att automatiskt kunna synka husarbete, komplettera manuellt i Fortnox.'
          return
        }
        if (!socialNo) {
          this.taxReductionErrorMessage =
            'Personnummer saknas för att automatiskt kunna synka husarbete, komplettera manuellt i Fortnox.'
          return
        }
        const houseWorkAmount = get(
          this.document,
          'prices.region.houseWorkAmount',
          0
        )
        if (!houseWorkAmount) return

        const propertyDesignation = get(
          mainClient,
          'region.propertyDesignation',
          null
        )
        // Create tax reduction data
        const taxReductionData = {
          AskedAmount: Math.floor(houseWorkAmount / 100), // Extra floor to catch non-allowed decimals
          CustomerName: name,
          ReferenceDocumentType: 'INVOICE',
          ReferenceNumber: this.createdInvoice.DocumentNumber, // invoiceNo
          SocialSecurityNumber: socialNo
        }

        // PropertyDesignation/ResidenceAssociationOrganisationNumber is optional to Fortnox
        const propertyType = get(propertyDesignation, 'propertyType')
        const propertyId = get(propertyDesignation, 'propertyId')
        const condominiumOrgNo = get(propertyDesignation, 'condominiumOrgNo')
        if (propertyId) taxReductionData.PropertyDesignation = propertyId
        if (propertyType === 'condominium' && condominiumOrgNo)
          taxReductionData.ResidenceAssociationOrganisationNumber =
            condominiumOrgNo

        const { data: taxReductionResult } =
          await fortnoxApi.createTaxReduction(taxReductionData)
        this.createdTaxReduction = taxReductionResult
      } catch (err) {
        // Do not show the error to the user...
        this.taxReductionErrorMessage =
          'Husarbete kunde inte läggas in, komplettera manuellt i Fortnox.'
      }
    },
    async _search(query) {
      try {
        this.isLoadingCustomers = true

        const lQuery = query.toLowerCase()
        // If we stored an old query that returned empty, and the new query starts with the old query we do not have to search again
        if (this.emptyQuery && lQuery && lQuery.startsWith(this.emptyQuery))
          return
        this.emptyQuery = null // Always clear old empty query as we got this far

        if (!lQuery) {
          this.searchResults = []
          return
        }

        const { data } = await fortnoxApi.searchCustomers(query)
        if (data && Array.isArray(data)) this.searchResults = data
        if (!data.length) this.emptyQuery = lQuery
      } finally {
        this.isLoadingCustomers = false
      }
    },
    search: debounce(async function func(query) {
      await this._search(query)
    }, 750),
    useSearchResult(fortnoxCustomer) {
      this.selectedCustomerObj = fortnoxCustomer
      this.searchQuery = fortnoxCustomer.CustomerNumber
    },
    async createProjectNote() {
      // Just append that a invoice was exported to project notes
      const { projectId } = this.document
      if (projectId && this.createdInvoice) {
        const text = `<p>${this.$formatDate(
          new Date(),
          'Pp'
        )}: Exporterade Fortnox faktura ${
          this.createdInvoice.DocumentNumber
        }</p>`
        await appendProjectNote(projectId, text)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@cling/styles/main.scss';

.modal-overlay {
  background-color: rgba($black, 0.1);
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  &-container {
    margin: 0 auto;
    background-color: $white;
    border-radius: 10px;
    position: relative;
    font-size: 16px;
    max-width: 90%;
    width: 500px;
    .full-screen & {
      width: 100%;
      height: 100%;
      display: block;
      justify-content: center;
      border-radius: 0;

      padding: 4em 1em 2em 1em;
      overflow: auto;
    }
  }
  &-main {
    max-width: 600px;
    font-size: 16px;
    &-title {
      font-weight: 700;
      font-size: 20px;
      margin-bottom: 1rem;
    }
    .full-screen & {
      margin: 0 auto;
    }
  }
  &-close {
    position: absolute;
    z-index: 2;
    top: 1em;
    right: 1em;
  }
}
.full-screen {
  height: 100%;
  overflow: auto;
}
</style>
